import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Section from '../components/section';
import { FiBriefcase, FiEdit, FiUser, FiTwitter } from 'react-icons/fi';
import SectionEntry from '../components/section-entry';
import Link from '../components/link';
import Header from '../components/header';
import PageWrapper from '../components/common/page-wrapper';
/*
TODO:
Ideas for iterations
- add dark mode
- add your own content
*/

const ResumePage = ({ data }) => {
  const posts =
    data.allMarkdownRemark.edges.map((edge) => ({
      ...edge.node.frontmatter,
      tags: edge.node.frontmatter.tags.split(','),
    })) || [];
  return (
    <Layout>
      <SEO
        title="Babs Craig's Resume"
        keywords={[
          `Babs Craig`,
          `Babasanya Craig`,
          `Front End Development`,
          `react`,
          `JavaScript`,
          `Developer`,
        ]}
      />
      <Header headerTitle={data.site.siteMetadata.title} />
      <PageWrapper>
        <Section title="About Me" icon={FiUser}>
          <SectionEntry
            renderBody={() => {
              return (
                <React.Fragment>
                  <p>
                    I've been building apps with JavaScript for close to 3
                    years. I've worked on a variety of teams and projects in
                    that time, but I always seem to default to smaller, more
                    nimble start-up teams. I work best in an environment of
                    passion and I'm hungry to learn while giving value. If you
                    have anything that seems like it's right up my alley - Give
                    me a{' '}
                    <span role="img" aria-label="telephone">
                      ☎️
                    </span>
                  </p>
                  <p>
                    Before code but after Law, I ran two businesses - a cross
                    country, shuttle service and an on-demand laundry service. I
                    also did some design work as an in-house designer for a
                    mobile games start-up. In addition to this, I've worked as
                    team lead on an 8 man team for two years, where we built a
                    fitness solution for busy people looking to connect with
                    other like minded fitness individuals.
                  </p>
                  <p>
                    <strong>Feel free to send me a message on </strong>
                    <Link
                      to="https://www.twitter.com/thebabscraig"
                      name="Twitter"
                      openInNewWindow
                      icon={FiTwitter}
                    />
                  </p>
                </React.Fragment>
              );
            }}
          />
        </Section>
        <Section title="Projects" icon={FiBriefcase}>
          <SectionEntry
            title="StreamMD"
            tags={['React', 'AWS Transcribe', 'Node', 'DrChrono']}
            liveLink="https://streammd.com/"
            renderBody={() => {
              return (
                <p>
                  StreamMD was built in response to the highly contagious nature
                  of COVID-19. It’s a solution that allows Doctors to meet with
                  patients who believe they may be at risk of exposure/infection
                  via teleconferencing from the safety of a quarantine location.
                  One of the challenges in building an app that deals with
                  medical information is that the app needs to be fully HIPAA
                  compliant, a chanllenge which we met. In addition, users could
                  also order test kits and have them delivered to their
                  location, test and then send them back to the doctors who
                  would have the results going into the appointment. Lastly, we
                  won first prize in our category at the recent{' '}
                  <a
                    targe="_blank"
                    rel="noreferrer"
                    href="https://developer.squareup.com/blog/the-square-small-business-hackathon-winners"
                  >
                    Square Small Business Hackathon
                  </a>
                </p>
              );
            }}
          />
          <SectionEntry
            title="Wisr"
            tags={['React']}
            liveLink="https://getwisr.com/"
            renderBody={() => {
              return (
                <p>
                  Wisr is a student and alumni engagement software that helps
                  connect both parties to each other for the purposes of
                  mentorship, internships and other opportunities. The problem
                  Wisr aimed to solved was the gap between students looking for
                  mentorship or job placement support and the alumni who can
                  meet these needs. The tool allowed schools to create a
                  subdomain where both sides of the users could connect - based
                  on their needs - in different ways and find each other for
                  both online and offline interactions in groups or
                  individually.{' '}
                </p>
              );
            }}
          />
          {/* TODO: fill this out */}
          {/* <SectionEntry
            title="G2i Developer Portal"
            tags={['React', 'TypeScript', 'Hasura', 'GraphQL', 'AWS']}
            liveLink="https://portal.g2i.co"
            renderBody={() => {
              return (
                <p>
                  The G2i developer portal connects developers with job
                  opportunities
                </p>
              );
            }}
          /> */}
          <SectionEntry
            title="Golf On The Go"
            tags={['React Native', 'GraphQL', 'AWS Amplify']}
            liveLink="http://golfonthego.com"
            renderBody={() => {
              return (
                <p>
                  Golf On The Go allows users use one monthly pass at a variety
                  of golf courses around them. The project is a React Native
                  project deployed to both iOS and Android app stores. I took
                  over this project at about 60% and working with all
                  stakeholders including previous developers on the project, was
                  able to deliver a finished product. The joy on my client's
                  face when the App got deployed to the App Store is one of the
                  best feelings I've had since I started coding 2 and a half
                  years ago
                </p>
              );
            }}
          />
          <SectionEntry
            title="percentiCal"
            tags={['Electron', 'NodeJS']}
            liveLink="https://github.com/babzcraig/percentical"
            codeLink="https://github.com/babzcraig/percentical"
            renderBody={() => {
              return (
                <p>
                  Started over the course of a weekend, percentiCal was built
                  out of my fascination with how quickly the year progresses and
                  an interest in picking up Electron. percentiCal is a menubar
                  app that displays the percent of the year gone by. The code is
                  available on GitHub and I hope to launch the app for free for
                  those who, like me, want to feel the hot breath of time on
                  their neck as it creeps up on us.
                </p>
              );
            }}
          />
          <SectionEntry
            title="Makaranta"
            tags={['TypeScript', 'React', 'Nodejs', 'GraphQL']}
            liveLink="https://makaranta.com.ng"
            renderBody={() => {
              return (
                <p>
                  A side project completed in partnership with an NGO. Makaranta
                  is a student/teacher managment software that helps admins keep
                  track of teachers deployed across the country in IDP camps and
                  other low-income areas to monitor the effectiveness of
                  teaching methods and provide remote support to teachers and
                  students alike. Child education is something close to my heart
                  and this project was extra special to me
                </p>
              );
            }}
          />
          <SectionEntry
            title="G2i Website"
            tags={['React', 'Gatsby', 'GraphQL']}
            liveLink="https://www.g2i.co/"
            renderBody={() => {
              return (
                <p>
                  I worked on the G2i marketing website project as a solo
                  undertaking in between working with the rest of the team on
                  the "platform" (an admin dashboard for internal employees). It
                  was built using Gatsbyjs, my first introduction, and it was as
                  they say, love at first code.
                </p>
              );
            }}
          />
          <SectionEntry
            title="Sendbox"
            tags={['React']}
            liveLink="https://sendbox.ng/"
            renderBody={() => {
              return (
                <p>
                  My very first job{' '}
                  <span role="img" aria-label="lovestruck emoji">
                    😍
                  </span>
                  . I was part of the team that built the Sendbox app. Sendbox
                  is a logistics and inventory management tool that helps
                  merchants use a unified dashboard to track inventory across
                  multiple sales channels and pulls in data from couriers in one
                  place so they can find the best options for fulfillment.
                </p>
              );
            }}
          />
          <SectionEntry
            title="electron-react-menubar-app-starter"
            tags={['Electron', 'React', 'OSS']}
            codeLink="https://github.com/babzcraig/electron-react-menubar-app-starter"
            renderBody={() => {
              return (
                <p>
                  This is an open source package that was created to bootstrap
                  an Electron React app that lives in the menubar. I created
                  this starter after building percentiCal to make it easier for
                  others who wanted to build the same types of apps. Currently
                  working on adding some configuration options so users can
                  select the type of app they'd like to create among other
                  features
                </p>
              );
            }}
          />
          <SectionEntry
            title="vanilla-nodejs-starter"
            tags={['Nodejs', 'OSS']}
            codeLink="https://github.com/babzcraig/vanilla-nodejs-starter"
            renderBody={() => {
              return (
                <p>
                  A vanilla nodejs server. I built this for usecases where one
                  needs a simple node server with no dependencies or as a
                  starting point for building out something much larger. It
                  comes with zero node dependencies (not even express) but has
                  body parsing and routing built in using native Nodejs modules.
                </p>
              );
            }}
          />
        </Section>
        <Section title="Recent Writing" icon={FiEdit}>
          {posts.map((post) => (
            <SectionEntry
              key={post.index}
              title={post.title}
              titleLink={post.path}
              tags={post.tags.slice(0, 2)}
            />
          ))}
        </Section>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___index] }
      filter: {
        frontmatter: {
          featured: { eq: true }
          type: { eq: "post" }
          published: { eq: true }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            description
            tags
            index
          }
        }
      }
    }
  }
`;

export default ResumePage;
